import React from "react";
import FounderImg from "../Assets/Img/founder.jpg";
function Founder() {
  return (
    <>
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <h6 className="section-title bg-white text-center text-primary px-3">
            Founder
          </h6>
          <h1 className="mb-2 text-center">Founder's Message</h1>

          <div className="row ">
            <div className="col-md-3 mt-4">
              <img src={FounderImg} className="img-fluid" />
            </div>
            <div className="col-md-9 mt-4">
              <p>
                At Techrysen, we are passionate about transforming ideas into
                reality through cutting-edge technology and creativity. As the
                founder, my vision has always been to provide businesses with
                innovative solutions that not only meet their needs but also
                empower them to excel in today’s fast-paced digital world.
              </p>
              <p>
                Our team specializes in a wide array of services, including web,
                app, and software development, SEO strategies that drive growth,
                and graphic designs that captivate and communicate. Every
                project we undertake is a testament to our commitment to
                quality, innovation, and customer satisfaction.
              </p>
              <p>
                Thank you for choosing Techrysen. Together, let’s build
                something extraordinary!
              </p>
              <p>
                Warm regards,
                <br />
                Ankur J Dutta <br />
                Founder, Techrysen
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Founder;
