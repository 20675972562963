import React from "react";
import { Link } from "react-router-dom";

function MobileNavbar({ isOpen, toggleSidebar }) {
  return (
    <>
      <div className={`mobile-sidebar ${isOpen ? "open" : ""}`}>
        <ul className="mobile-sidebar-list">
          <li>
            <Link to="/" onClick={toggleSidebar}>
              Home
            </Link>
          </li>

          <li>
            <Link to="/about-us-tech-rysen" onClick={toggleSidebar}>
              About Us
            </Link>
          </li>

          <li>
            <Link to="/services-tech-rysen" onClick={toggleSidebar}>
              Our Services
            </Link>
          </li>

          <li>
            <Link to="/contact-us-tech-rysen" onClick={toggleSidebar}>
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default MobileNavbar;
