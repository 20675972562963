import React from "react";
import crmdev from "../Assets/Img/crmdev.jpg";
import ecomdev from "../Assets/Img/ecommercedev.jpg";
import mobileappdev from "../Assets/Img/mobileapp.jpg";
import webdev from "../Assets/Img/webdev.jpg";
import facetimedev from "../Assets/Img/5064.jpg";
import seodev from "../Assets/Img/138498.jpg";
import graphicdesign from "../Assets/Img/graphicdesign.jpg";
import GoToTop from "../GoToTop";

function Services() {
  return (
    <>
      <GoToTop />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Services
            </h6>
            <h1 className="mb-5">Our Services</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={webdev}
                  alt="Website Design and Development"
                />
                <div className="p-4">
                  <h5>Website Design & Development</h5>
                  <p>
                    We craft high-performance websites that are visually
                    stunning, user-friendly, and optimized for conversion.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={ecomdev}
                  alt="Ecommerce Development"
                />
                <div className="p-4">
                  <h5>E-Commerce Development</h5>
                  <p>
                    Developing user-friendly and secure eCommerce websites with
                    seamless navigation, integrated payment systems, and
                    optimized performance to boost online sales.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={crmdev}
                  alt="crm development"
                />
                <div className="p-4">
                  {/* <i className="fa fa-3x fa-globe text-primary mb-4"></i> */}
                  <h5>CRM Development</h5>
                  <p>
                    Creating tailored CRM systems to streamline customer
                    interactions, improve data management, and enhance business
                    efficiency and growth.
                  </p>

                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={mobileappdev}
                  alt="Mobile App Development"
                />
                <div className="p-4">
                  <h5>Mobile Application Development</h5>
                  <p>
                    Creating high-performance mobile apps with intuitive design
                    and tailored functionality for your needs.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={facetimedev}
                  alt="Face Scan Attendance Software"
                />
                <div className="p-4">
                  <h5>Face Scan Attendance Software</h5>
                  <p>
                    Efficient attendance management using advanced facial
                    recognition for accurate, secure workforce tracking.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={seodev}
                  alt="Search Engine Optimization"
                />
                <div className="p-4">
                  <h5>Search Engine Optimization</h5>
                  <p>
                    We employ a strategic SEO approach to propel you to the top
                    of search engine results pages and drive organic traffic.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={graphicdesign}
                  alt="graphic design"
                />
                <div className="p-4">
                  <h5>Graphic Designing</h5>
                  <p>
                    Transforming ideas into visually compelling graphics, from
                    logos and branding to marketing materials, with creative and
                    professional design solutions.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
