import React, { useState } from "react";
import about from "../Assets/Img/about.webp";
import crmdev from "../Assets/Img/crmdev.jpg";
import ecomdev from "../Assets/Img/ecommercedev.jpg";
import mobileappdev from "../Assets/Img/mobileapp.jpg";
import webdev from "../Assets/Img/webdev.jpg";
import facetimedev from "../Assets/Img/5064.jpg";
import seodev from "../Assets/Img/138498.jpg";
import graphicdesign from "../Assets/Img/graphicdesign.jpg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { db } from "../config/firebase";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import heroImg from "../Assets/Img/bg-hero.webp";
import Founder from "./Founder";

function Home() {
  const structuredData = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    name: "Tech-Rysen Web Development Services",
    url: "https://techrysen.com/tech-rysen-web-development-services",
    logo: "https://techrysen.com/images/tech-rysen-logo.png",
    description:
      "Professional web development services by Tech-Rysen. We offer bespoke website solutions tailored to your business needs.",
    address: {
      "@type": "PostalAddress",
      streetAddress: "",
      addressLocality: "Delhi",
      addressRegion: "Delhi",
      postalCode: "110009",
      addressCountry: "IN",
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+917086952212",
      contactType: "Customer Service",
    },
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState("");

  const handleForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (email || contact) {
      if (contact && contact.length < 10) {
        toast.error("Phone number can not be less than 10 digit");
        return;
      } else if (contact && contact.length > 13) {
        toast.error("Phone Number Can not ber greater than 13 digit");
        return;
      } else if (name.length < 1) {
        toast.error("Please Enter Name");
        return;
      } else {
        try {
          // console.log()
          const formObj = await db.collection("CustomerQueries").add({
            name: name,
            email: email,
            message: message,
            date: date,
            contact_no: contact,
          });
          Swal.fire({
            title: "Success",
            icon: "success",
            text: "Thank You for Connecting Us, We will get back to you soon ! ",
          });
          setName("");
          setContact("");
          setEmail("");
          setMessage("");
          setDate("");
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toast.error("Something Went Wrong");
        }
      }
    } else {
      toast.error("Please Provide Email or Contact No .");
      setLoading(false);
      return;
    }
  };
  return (
    <>
      <Helmet>
        <title>TechRysen | Your Partner in Digital Growth</title>
        <meta
          name="description"
          content="Professional web development services by Tech-Rysen. We offer bespoke website solutions tailored to your business needs."
        />
        <meta
          name="keywords"
          content="Tech-Rysen, web development, web design, bespoke websites, business websites, web solutions"
        />
        <meta
          property="og:title"
          content="Tech-Rysen Web Development Services"
        />
        <meta
          property="og:description"
          content="Professional web development services by Tech-Rysen. We offer bespoke website solutions tailored to your business needs."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://techrysen.com/tech-rysen-web-development-services"
        />
        <meta
          property="og:image"
          content="https://techrysen.com/images/tech-rysen-web-development-services.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Tech-Rysen Web Development Services"
        />
        <meta
          name="twitter:description"
          content="Professional web development services by Tech-Rysen. We offer bespoke website solutions tailored to your business needs."
        />
        {/* <meta
          name="twitter:image"
          content="https://techrysen.com/"
        /> */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <ToastContainer />
      <section className="section-hero">
        <div className="hero">
          <div className="hero-text-box">
            <h1 className="heading-primary">Your Partner in Digital Growth</h1>
            <p className="hero-description">
              Empowering businesses with innovative tech solutions, driving
              success in E-Commerce and CRM.
            </p>
            <Link
              to="/services-tech-rysen"
              className="btn btn-primary margin-right-btn"
            >
              Explore Services
            </Link>
            <Link to="/about-us-tech-rysen" className="btn btn--outline">
              Learn more &darr;
            </Link>
          </div>
          <div className="hero-img-box">
            <img
              src={heroImg}
              alt="Woman enjoying food, meals in storage container, and food bowls on a table"
              className="hero-img"
            />
          </div>
          <div className="delivered-meals">
            <div className="delivered-imgs">
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-1.jpg"
                alt="Customer photo"
              />
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-2.jpg"
                alt="Customer photo"
              />
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-3.jpg"
                alt="Customer photo"
              />
              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-4.jpg"
                alt="Customer photo"
              />

              <img
                src="https://prayagtandon.github.io/Omnifood-Project/Hero-section/img/customers/customer-5.jpg"
                alt="Customer photo"
              />
            </div>
            <p className="delivered-text">
              <span>200+</span> Projects Delivered!
            </p>
          </div>
        </div>
      </section>

      {/* <!-- About Start --> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={about}
                  alt="techRysen Your Partner in Digital Growth"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                About Us
              </h6>
              <h1 className="mb-4">
                Welcome to <span className="text-primary">TechRysen</span>
              </h1>
              <p className="mb-4">
                The TechRysen excels with unmatched expertise, innovative
                solutions, and a client-centric approach, delivering top-tier
                web and app development tailored to unique business needs.
              </p>
              <p className="mb-4">
                Established with a vision to revolutionize the digital
                landscape, we specialize in providing top-tier tech services,
                including CRM Development, Web Design & Development, E-Commerce
                Development, Mobile app development, and Graphic Design. Our
                mission is to empower businesses by transforming their digital
                presence and driving their success through cutting-edge
                technology and creative solutions.
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>CRM
                    Development
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Web Design & Development
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    E-Commerce Development
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>Face
                    Scan Attendance System
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Mobile App Development
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Search Engine Optimization
                  </p>
                </div>

                {/* <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>24/7
                    Service
                  </p>
                </div> */}
              </div>
              <Link
                className="btn btn-primary py-3 px-5 mt-2"
                to="/about-us-tech-rysen"
              >
                Read More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About End --> */}

      {/* <!-- Service Start --> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Services
            </h6>
            <h1 className="mb-5">Our Services</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={webdev}
                  alt="Website Design and Development"
                />
                <div className="p-4">
                  <h5>Website Design & Development</h5>
                  <p>
                    We craft high-performance websites that are visually
                    stunning, user-friendly, and optimized for conversion.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={ecomdev}
                  alt="Ecommerce Development"
                />
                <div className="p-4">
                  <h5>E-Commerce Development</h5>
                  <p>
                    Developing user-friendly and secure eCommerce websites with
                    seamless navigation, integrated payment systems, and
                    optimized performance to boost online sales.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={crmdev}
                  alt="crm development"
                />
                <div className="p-4">
                  {/* <i className="fa fa-3x fa-globe text-primary mb-4"></i> */}
                  <h5>CRM Development</h5>
                  <p>
                    Creating tailored CRM systems to streamline customer
                    interactions, improve data management, and enhance business
                    efficiency and growth.
                  </p>

                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={mobileappdev}
                  alt="Mobile App Development"
                />
                <div className="p-4">
                  <h5>Mobile Application Development</h5>
                  <p>
                    Creating high-performance mobile apps with intuitive design
                    and tailored functionality for your needs.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={facetimedev}
                  alt="Face Scan Attendance Software"
                />
                <div className="p-4">
                  <h5>Face Scan Attendance Software</h5>
                  <p>
                    Efficient attendance management using advanced facial
                    recognition for accurate, secure workforce tracking.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={seodev}
                  alt="Search Engine Optimization"
                />
                <div className="p-4">
                  <h5>Search Engine Optimization</h5>
                  <p>
                    We employ a strategic SEO approach to propel you to the top
                    of search engine results pages and drive organic traffic.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <div className="service-item rounded">
                <img
                  style={{ width: "100%" }}
                  src={graphicdesign}
                  alt="graphic design"
                />
                <div className="p-4">
                  <h5>Graphic Designing</h5>
                  <p>
                    Transforming ideas into visually compelling graphics, from
                    logos and branding to marketing materials, with creative and
                    professional design solutions.
                  </p>
                  {/* <a href="/">
                    Learn More{" "}
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Service End --> */}

      {/* <!-- Destination Start --> */}
      {/* <div className="container-xxl py-5 destination">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Destination
            </h6>
            <h1 className="mb-5">Popular Destination</h1>
          </div>
          <div className="row g-3">
            <div className="col-lg-7 col-md-6">
              <div className="row g-3">
                <div
                  className="col-lg-12 col-md-12 wow zoomIn"
                  data-wow-delay="0.1s"
                >
                  <a className="position-relative d-block overflow-hidden" href="">
                    <img className="img-fluid" src="img/destination-1.jpg" alt="" />
                    <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                      30% OFF
                    </div>
                    <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                      Thailand
                    </div>
                  </a>
                </div>
                <div
                  className="col-lg-6 col-md-12 wow zoomIn"
                  data-wow-delay="0.3s"
                >
                  <a className="position-relative d-block overflow-hidden" href="">
                    <img className="img-fluid" src="img/destination-2.jpg" alt="" />
                    <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                      25% OFF
                    </div>
                    <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                      Malaysia
                    </div>
                  </a>
                </div>
                <div
                  className="col-lg-6 col-md-12 wow zoomIn"
                  data-wow-delay="0.5s"
                >
                  <a className="position-relative d-block overflow-hidden" href="">
                    <img className="img-fluid" src="img/destination-3.jpg" alt="" />
                    <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                      35% OFF
                    </div>
                    <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                      Australia
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 col-md-6 wow zoomIn"
              data-wow-delay="0.7s"
              style={{ minHeight: "350px" }}
            >
              <a
                className="position-relative d-block h-100 overflow-hidden"
                href=""
              >
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src="img/destination-4.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">
                  20% OFF
                </div>
                <div className="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">
                  Indonesia
                </div>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Destination Start --> */}

      {/* <!-- Package Start --> */}
      {/* <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Packages
            </h6>
            <h1 className="mb-5">Awesome Packages</h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="{contact}" alt="" />
                </div>
                <div className="d-flex border-bottom">
                  <small className="flex-fill text-center border-end py-2">
                    <i className="fa fa-map-marker-alt text-primary me-2"></i>
                    Thailand
                  </small>
                  <small className="flex-fill text-center border-end py-2">
                    <i className="fa fa-calendar-alt text-primary me-2"></i>3 days
                  </small>
                  <small className="flex-fill text-center py-2">
                    <i className="fa fa-user text-primary me-2"></i>2 Person
                  </small>
                </div>
                <div className="text-center p-4">
                  <h3 className="mb-0">$149.00</h3>
                  <div className="mb-3">
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                  </div>
                  <p>
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <div className="d-flex justify-content-center mb-2">
                    <a
                      href="#"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Read More
                    </a>
                    <a
                      href="#"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0px 30px 30px 0" }}
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="img/package-2.jpg" alt="" />
                </div>
                <div className="d-flex border-bottom">
                  <small className="flex-fill text-center border-end py-2">
                    <i className="fa fa-map-marker-alt text-primary me-2"></i>
                    Indonesia
                  </small>
                  <small className="flex-fill text-center border-end py-2">
                    <i className="fa fa-calendar-alt text-primary me-2"></i>3 days
                  </small>
                  <small className="flex-fill text-center py-2">
                    <i className="fa fa-user text-primary me-2"></i>2 Person
                  </small>
                </div>
                <div className="text-center p-4">
                  <h3 className="mb-0">$139.00</h3>
                  <div className="mb-3">
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                  </div>
                  <p>
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <div className="d-flex justify-content-center mb-2">
                    <a
                      href="#"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Read More
                    </a>
                    <a
                      href="#"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0px 30px 30px 0" }}
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="package-item">
                <div className="overflow-hidden">
                  <img className="img-fluid" src="img/package-3.jpg" alt="" />
                </div>
                <div className="d-flex border-bottom">
                  <small className="flex-fill text-center border-end py-2">
                    <i className="fa fa-map-marker-alt text-primary me-2"></i>
                    Malaysia
                  </small>
                  <small className="flex-fill text-center border-end py-2">
                    <i className="fa fa-calendar-alt text-primary me-2"></i>3 days
                  </small>
                  <small className="flex-fill text-center py-2">
                    <i className="fa fa-user text-primary me-2"></i>2 Person
                  </small>
                </div>
                <div className="text-center p-4">
                  <h3 className="mb-0">$189.00</h3>
                  <div className="mb-3">
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                    <small className="fa fa-star text-primary"></small>
                  </div>
                  <p>
                    Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit
                    diam amet diam eos
                  </p>
                  <div className="d-flex justify-content-center mb-2">
                    <a
                      href="#"
                      className="btn btn-sm btn-primary px-3 border-end"
                      style={{ borderRadius: "30px 0 0 30px" }}
                    >
                      Read More
                    </a>
                    <a
                      href="#"
                      className="btn btn-sm btn-primary px-3"
                      style={{ borderRadius: "0px 30px 30px 0" }}
                    >
                      Book Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Package End --> */}

      {/* <!-- Booking Start --> */}
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="booking p-5">
            <div className="row g-5 align-items-center">
              <div className="col-md-6 text-white">
                <h6 className="text-white text-uppercase">Contact Us</h6>
                <h1 className="text-white mb-4">Online Consultant</h1>
                <p className="mb-4">
                  We specialize in eCommerce development and CRM systems,
                  offering expert solutions for your business needs.
                </p>
                <p className="mb-4">
                  For expert guidance and tailored solutions, contact us today.
                  Our online consultants are here to assist with all your
                  inquiries, provide detailed information, and offer
                  personalized advice. Reach out via phone, email, or our
                  website to explore how we can support your business needs and
                  drive success.
                </p>
                {/* <a className="btn btn-outline-light py-3 px-5 mt-2" href="">
                  Read More
                </a> */}
              </div>
              <div className="col-md-6">
                <h1 className="text-white mb-4">Book An Appoinment</h1>
                <form onSubmit={handleForm}>
                  <div className="row g-3">
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-transparent"
                          id="name"
                          placeholder="Your Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <label for="name">Your Name</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control bg-transparent"
                          id="phone"
                          placeholder="Your Contact No."
                          value={contact}
                          onChange={(e) => setContact(e.target.value)}
                        />
                        <label for="phone">Phone</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control bg-transparent"
                          id="email"
                          placeholder="Your Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label for="email">Your Email</label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="form-floating date"
                        id="date3"
                        data-target-input="nearest"
                      >
                        <input
                          type="date"
                          className="form-control bg-transparent datetimepicker-input"
                          id="datetime"
                          placeholder="Preferred TIme Slot"
                          value={date}
                          onChange={(e) => setDate(e.target.value)}
                        />
                        <label for="datetime">Date & Time</label>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-floating">
                        <textarea
                          className="form-control bg-transparent"
                          placeholder="Special Request"
                          id="message"
                          style={{ height: "100px" }}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                        <label for="message">Your Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      {loading ? (
                        <>
                          <button
                            className="btn btn-outline-light w-100 py-3"
                            disabled
                          >
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn-outline-light w-100 py-3"
                            type="submit"
                          >
                            Book Now
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Booking Start --> */}

      {/* <!-- Process Start --> */}
      {/* <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center pb-4 wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Process
            </h6>
            <h1 className="mb-5">3 Easy Steps</h1>
          </div>
          <div className="row gy-5 gx-4 justify-content-center">
            <div
              className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i className="fa fa-globe fa-3x text-white"></i>
                </div>
                <h5 className="mt-4">Choose A Destination</h5>
                <hr className="w-25 mx-auto bg-primary mb-1" />
                <hr className="w-50 mx-auto bg-primary mt-0" />
                <p className="mb-0">
                  Tempor erat elitr rebum clita dolor diam ipsum sit diam amet
                  diam eos erat ipsum et lorem et sit sed stet lorem sit
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i className="fa fa-dollar-sign fa-3x text-white"></i>
                </div>
                <h5 className="mt-4">Pay Online</h5>
                <hr className="w-25 mx-auto bg-primary mb-1" />
                <hr className="w-50 mx-auto bg-primary mt-0" />
                <p className="mb-0">
                  Tempor erat elitr rebum clita dolor diam ipsum sit diam amet
                  diam eos erat ipsum et lorem et sit sed stet lorem sit
                </p>
              </div>
            </div>
            <div
              className="col-lg-4 col-sm-6 text-center pt-4 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="position-relative border border-primary pt-5 pb-4 px-4">
                <div
                  className="d-inline-flex align-items-center justify-content-center bg-primary rounded-circle position-absolute top-0 start-50 translate-middle shadow"
                  style={{ width: "100px", height: "100px" }}
                >
                  <i className="fa fa-plane fa-3x text-white"></i>
                </div>
                <h5 className="mt-4">Fly Today</h5>
                <hr className="w-25 mx-auto bg-primary mb-1" />
                <hr className="w-50 mx-auto bg-primary mt-0" />
                <p className="mb-0">
                  Tempor erat elitr rebum clita dolor diam ipsum sit diam amet
                  diam eos erat ipsum et lorem et sit sed stet lorem sit
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Process Start --> */}

      {/* <!-- Testimonial Start --> */}
      {/* <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="text-center">
            <h6 className="section-title bg-white text-center text-primary px-3">
              Testimonial
            </h6>
            <h1 className="mb-5">Our Clients Say!!!</h1>
          </div>
          <div className="owl-carousel testimonial-carousel position-relative">
            <div className="testimonial-item bg-white text-center border p-4">
              <img
                className="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src="img/testimonial-1.jpg"
                style={{ width: "80px", height: "80px" }}
              />
              <h5 className="mb-0">John Doe</h5>
              <p>New York, USA</p>
              <p className="mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <div className="testimonial-item bg-white text-center border p-4">
              <img
                className="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src="img/testimonial-2.jpg"
                style={{ width: "80px", height: "80px" }}
              />
              <h5 className="mb-0">John Doe</h5>
              <p>New York, USA</p>
              <p className="mt-2 mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <div className="testimonial-item bg-white text-center border p-4">
              <img
                className="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src="img/testimonial-3.jpg"
                style={{ width: "80px", height: "80px" }}
              />
              <h5 className="mb-0">John Doe</h5>
              <p>New York, USA</p>
              <p className="mt-2 mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
            <div className="testimonial-item bg-white text-center border p-4">
              <img
                className="bg-white rounded-circle shadow p-1 mx-auto mb-3"
                src="img/testimonial-4.jpg"
                style={{ width: "80px", height: "80px" }}
              />
              <h5 className="mb-0">John Doe</h5>
              <p>New York, USA</p>
              <p className="mt-2 mb-0">
                Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam
                amet diam et eos. Clita erat ipsum et lorem et sit.
              </p>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- Testimonial End --> */}
      <Founder />
    </>
  );
}

export default Home;
