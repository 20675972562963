import React from "react";
import about from "../Assets/Img/about.webp";
import GoToTop from "../GoToTop";
import Founder from "./Founder";

function About() {
  return (
    <>
      <GoToTop />
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div
              className="col-lg-6 wow fadeInUp"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="img-fluid position-absolute w-100 h-100"
                  src={about}
                  alt="techRysen Your Partner in Digital Growth"
                  style={{ objectFit: "cover" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">
                About Us
              </h6>
              <h1 className="mb-4">
                Welcome to <span className="text-primary">TechRysen</span>
              </h1>
              <p className="mb-4">
                The TechRysen excels with unmatched expertise, innovative
                solutions, and a client-centric approach, delivering top-tier
                web and app development tailored to unique business needs.
              </p>
              <p className="mb-4">
                Established with a vision to revolutionize the digital
                landscape, we specialize in providing top-tier tech services,
                including CRM Development, Web Design & Development, E-Commerce
                Development, Mobile app development, and Graphic Design. Our
                mission is to empower businesses by transforming their digital
                presence and driving their success through cutting-edge
                technology and creative solutions.
              </p>

              <div className="row gy-2 gx-4 mb-4">
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>CRM
                    Development
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Web Design & Development
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    E-Commerce Development
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>Face
                    Scan Attendance System
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Mobile App Development
                  </p>
                </div>
                <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>
                    Search Engine Optimization
                  </p>
                </div>

                {/* <div className="col-sm-6">
                  <p className="mb-0">
                    <i className="fa fa-arrow-right text-primary me-2"></i>24/7
                    Service
                  </p>
                </div> */}
              </div>
              {/* <a className="btn btn-primary py-3 px-5 mt-2" href="">
                Read More
              </a> */}
            </div>
          </div>
        </div>
      </div>

      <Founder />
    </>
  );
}

export default About;
